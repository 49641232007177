import config from './config';
import { PaymentProviders } from './paymentProviders';

export const updatePayment = (
    trackingId,
    paymentNonce,
    apiRequestData,
    paymentProvider,
    providerSpecificInfo
) => {
    const url = config.API_BASE_URL + 'Widget/UpdateDonationAsync';
    const options = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            ...apiRequestData,
            trackingId,
            paymentNonce,
            notifyResult: 0,
            paymentProvider: PaymentProviders[paymentProvider].id,
            providerSpecificInfo
        })
    };
    return fetch(url, options).then(response => response);
};
