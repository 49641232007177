export function loadScript(src, async, defer) {
    return new Promise((resolve, reject) => {
        if (typeof document !== 'undefined') {
            const js = document.createElement('script');
            if (async) {
                js.async = true;
            }
            if (defer) {
                js.defer = true;
            }
            js.src = src;
            js.onload = resolve;
            js.onerror = reject;
            document.head.appendChild(js);
        } else {
            reject();
        }
    });
}
