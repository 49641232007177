import config from './config';
import { loadScript } from './loadScript';

let stripePaymentPromise = Promise.resolve();
let stripePaymentEvent = null;

const onStripeLoaded = amount => {
    const stripe = window.Stripe(config.ORG_HUNTER_PUBLIC_KEY);

    const stripePaymentRequest = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
            label: 'Give',
            amount: amount * 100
        }
    });

    var elements = stripe.elements();
    var prButton = elements.create('paymentRequestButton', {
        paymentRequest: stripePaymentRequest
    });

    // Check the availability of the Payment Request API first.
    stripePaymentPromise = new Promise((resolve, reject) => {
        stripePaymentRequest.canMakePayment().then(function(result) {
            if (result) {
                prButton.mount('#payment-request-button');
            } else {
                reject('No payment method available.');
                if (!!document.getElementById('payment-request-button')) {
                    document.getElementById('payment-request-button').style.display =
                        'none';
                }
            }
        });
        stripePaymentRequest.on('token', function(ev) {
            stripePaymentEvent = ev;
            resolve(ev.token.id);
        });
    });
};

export const initialize = amount => {
    const load = !!window.Stripe
        ? Promise.resolve()
        : loadScript('https://js.stripe.com/v3/');
    return load.then(() => onStripeLoaded(amount));
};

export const stripeFlow = () => stripePaymentPromise;
export const onSuccess = () =>
    stripePaymentEvent && stripePaymentEvent.complete('success');
export const onError = () => stripePaymentEvent && stripePaymentEvent.complete('fail');
