import { createGlobalStyle } from 'styled-components';

import _avertaWoff from './assets/font/averta.woff';
import _avertaWoff2 from './assets/font/averta.woff2';
import _avertaBoldWoff from './assets/font/averta-bold.woff';
import _avertaBoldWoff2 from './assets/font/averta-bold.woff2';
import _avertaLightWoff from './assets/font/averta-light.woff';
import _avertaLightWoff2 from './assets/font/averta-light.woff2';
import _avertaSemiBoldWoff from './assets/font/averta-semibold.woff';
import _avertaSemiBoldWoff2 from './assets/font/averta-semibold.woff2';

let avertaWoff = _avertaWoff;
let avertaWoff2 = _avertaWoff2;
let avertaBoldWoff = _avertaBoldWoff;
let avertaBoldWoff2 = _avertaBoldWoff2;
let avertaLightWoff = _avertaLightWoff;
let avertaLightWoff2 = _avertaLightWoff2;
let avertaSemiBoldWoff = _avertaSemiBoldWoff;
let avertaSemiBoldWoff2 = _avertaSemiBoldWoff2;

function preloadFont(fontPath) {
    if (typeof document !== 'undefined') {
        var link = document.createElement('link');
        link.href = fontPath;
        link.rel = 'preload';
        link.as = 'font';
        link.type = 'font/woff2';
        document.body.appendChild(link);
    }
}

preloadFont(avertaWoff2);
preloadFont(avertaBoldWoff2);
preloadFont(avertaLightWoff2);
preloadFont(avertaSemiBoldWoff2);

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Averta';
    src:
      url(${avertaLightWoff2}) format('woff2'),
      url(${avertaLightWoff}) format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Averta';
    src:
      url(${avertaWoff2}) format('woff2'),
      url(${avertaWoff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Averta';
    src:
      url(${avertaSemiBoldWoff2}) format('woff2'),
      url(${avertaSemiBoldWoff}) format('woff');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Averta';
    src:
      url(${avertaBoldWoff2}) format('woff2'),
      url(${avertaBoldWoff}) format('woff');
    font-weight: 700;
    font-style: normal;
  }

  h3 {
    font-weight: 400;
    margin: 0.5em 0;
  }

  p {
    margin: 0 0 1em;
  }

  *,
  *::before,
  *::after {
    font-family: Averta;
    box-sizing: border-box;

    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    font-smooth: always;

    min-height: 0;
    min-width: 0;
  }

  body {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }

  #root {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    & > div {
      flex: 1;
    }
  }
`;
