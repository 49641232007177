import braintree from 'braintree-web';
import config from './config';
import { loadScript } from './loadScript';

let googlePaymentsClient = null;
export const getGooglePaymentsClient = () => googlePaymentsClient;
let googleSupported = null;
export const getGoogleSupported = () => googleSupported;

let braintreeClient = null;

export const initialize = (clientToken, type, amount) => {
    const braintreeClientProm = braintree.client
        .create({
            authorization: clientToken
        })
        .then(btc => (braintreeClient = btc));
    const setupProms = [braintreeClientProm];

    if (type === 'googlePay') {
        setupProms.push(
            loadScript('https://pay.google.com/gp/p/js/pay.js').then(() => {
                if (window.google) {
                    googlePaymentsClient = new window.google.payments.api.PaymentsClient({
                        environment: config.GOOGLE_PAY_ENVIRONMENT
                    });

                    return braintreeClientProm
                        .then(() => {
                            const request = {
                                client: braintreeClient,
                                googlePayVersion: 2,
                                googleMerchantId: config.GOOGLE_MERCHANT_ID
                            };
                            return braintree.googlePayment.create(request);
                        })
                        .then(googleInstance => {
                            var paymentDataRequest = googleInstance.createPaymentDataRequest(
                                {
                                    transactionInfo: {
                                        currencyCode: 'USD',
                                        totalPriceStatus: 'FINAL',
                                        totalPrice: amount
                                    }
                                }
                            );

                            return googlePaymentsClient.isReadyToPay(paymentDataRequest);
                        })
                        .then(res => {
                            googleSupported = res.result;
                            if (!googleSupported)
                                throw new Error('Google Pay not supported');
                        })
                        .catch(err => {
                            googleSupported = false;
                            throw err;
                        });
                }
            })
        );
    }

    return Promise.all(setupProms);
};

export const venmoFlow = clientToken => {
    return braintree.venmo
        .create({ client: braintreeClient, allowNewBrowserTab: false })
        .then(venmo => venmo.tokenize());
};

export const googleFlow = (clientToken, amount) => {
    const request = {
        client: braintreeClient,
        googlePayVersion: 2,
        googleMerchantId: config.GOOGLE_MERCHANT_ID
    };
    return braintree.googlePayment.create(request).then(googleInstance => {
        var paymentDataRequest = googleInstance.createPaymentDataRequest({
            transactionInfo: {
                currencyCode: 'USD',
                totalPriceStatus: 'FINAL',
                totalPrice: amount
            }
        });

        return googlePaymentsClient
            .loadPaymentData(paymentDataRequest)
            .then(function(paymentData) {
                return googleInstance.parseResponse(paymentData);
            });
    });
};

export const appleFlow = (clientToken, amount, label) => {
    /* global ApplePaySession */
    var result = new Promise((resolve, reject) => {
        braintree.applePay.create({ client: braintreeClient }).then(applePay => {
            var paymentRequest = applePay.createPaymentRequest({
                total: { amount, label }
            });
            var session = new ApplePaySession(2, paymentRequest);
            session.onvalidatemerchant = function(event) {
                applePay
                    .performValidation({
                        validationURL: event.validationURL,
                        displayName: 'Donation via Action Button'
                    })
                    .then(merchantSession => {
                        session.completeMerchantValidation(merchantSession);
                    })
                    .catch(err => {
                        session.abort();
                        reject(err);
                    });
            };

            session.onpaymentauthorized = function(event) {
                applePay
                    .tokenize({
                        token: event.payment.token
                    })
                    .then(resolve)
                    .catch(reject);
            };

            session.begin();
        });
    });

    return result;
};
