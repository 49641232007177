
export const PaymentProviders = {
    venmo: {
        displayName: 'Venmo',
        id: 2
    },
    applePay: {
        displayName: 'Apple Pay',
        id: 3
    },
    googlePay: {
        displayName: 'Google Pay',
        id: 1
    }
}
