import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';

import {
    ActionButtonLogo,
    Body,
    Button,
    ButtonContainer,
    Description,
    ErrorMessage,
    Footer,
    Header,
    Spinner,
    StripeButton,
    Title
} from './uiComponents';
import * as braintree from './braintree';
import * as stripe from './stripe';

import { updatePayment } from './api';
import { isLongText, urlParams } from './utils';
import { PaymentProviders } from './paymentProviders';

import { GlobalStyle } from './App.styles';

Sentry.init({ dsn: 'https://98a13a513b814568bd2202bd327cbb06@sentry.io/1397771' });

class App extends Component {
    constructor(props) {
        super(props);
        this.state = { error: false, ready: false, submitting: true };
    }

    componentDidMount() {
        if (urlParams.method === 'braintree') {
            braintree
                .initialize(urlParams.clientId, urlParams.type, urlParams.amount)
                .then(() => this.setState({ submitting: false, ready: true }));
        } else if (urlParams.method === 'stripe') {
            stripe
                .initialize(urlParams.amount)
                .then(() => {
                    this.setState({ submitting: false, ready: true });
                })
                .then(() => this.doPayment());
        } else {
            this.setState({ error: true });
            throw new Error('Unrecognized payment method');
        }
    }

    getAuthorizePaymentFlow() {
        if (urlParams.method === 'braintree') {
            let flow = null;
            if (urlParams.type === 'googlePay') {
                flow = braintree.googleFlow(urlParams.clientId, urlParams.amount);
            } else if (urlParams.type === 'applePay') {
                flow = braintree.appleFlow(
                    urlParams.clientId,
                    urlParams.amount,
                    urlParams.label
                );
            } else if (urlParams.type === 'venmo') {
                flow = braintree.venmoFlow(urlParams.clientId);
            } else {
                throw new Error('Unrecognized payment type.');
            }
            return flow.then(val => {
                if (urlParams.method == 'braintree' && val.type === 'VenmoAccount') {
                    return updatePayment(
                        urlParams.id,
                        val.nonce,
                        urlParams.apiData,
                        urlParams.type,
                        val.details.username
                    );
                }
                return updatePayment(
                    urlParams.id,
                    val.nonce,
                    urlParams.apiData,
                    urlParams.type
                );
            });
        } else {
            return stripe
                .stripeFlow()
                .then(token =>
                    updatePayment(urlParams.id, token, urlParams.apiData, urlParams.type)
                )
                .then(() => stripe.onSuccess())
                .catch(err => {
                    this.setState({ error: true, ready: false });
                    stripe.onError();
                    throw err;
                });
        }
    }

    doPayment() {
        if (!urlParams.method) throw new Error('payment method is required');
        if (!urlParams.type) throw new Error('payment type required');
        if (!urlParams.id) throw new Error('tracking id required');
        if (!urlParams.clientId) throw new Error('Braintree client id required');

        this.getAuthorizePaymentFlow()
            .then(() => {
                this.setState({ submitting: false });
                window.close();
            })
            .catch(err => {
                this.setState({ submitting: false });
                Sentry.captureException(err);
            });
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ error });
        Sentry.withScope(scope => {
            Object.keys(errorInfo).forEach(key => {
                scope.setExtra(key, errorInfo[key]);
            });
            Sentry.captureException(error);
        });
    }

    render() {
        const { ready, error, submitting } = this.state;
        const titleString = `You've selected ${PaymentProviders[urlParams.type].displayName} to process your $${urlParams.amount} donation.`;
        const descriptionString = `Click below to complete your donation to ${urlParams.charity}.`;
        return (
            <React.Fragment>
                <GlobalStyle />
                <Body>
                    <Header>
                        <ActionButtonLogo />
                    </Header>
                    <Title
                        isShortText={!isLongText(titleString, 28)}
                        isLongText={isLongText(titleString, 60)}
                        isExtraLongText={isLongText(titleString, 95)}
                        dangerouslySetInnerHTML={{
                            __html: titleString
                        }}
                    />
                    <Description
                        isLongText={isLongText(descriptionString, 90)}
                        isExtraLongText={isLongText(descriptionString, 160)}
                        dangerouslySetInnerHTML={{
                            __html: descriptionString
                        }}
                    />
                    {ready && !error ? (
                        <ButtonContainer>
                            {submitting ? (
                                <Spinner />
                            ) : urlParams.method === 'braintree' ? (
                                Button(urlParams.type, () => {
                                    this.setState({ submitting: true });
                                    this.doPayment();
                                })
                            ) : (
                                StripeButton()
                            )}
                        </ButtonContainer>
                    ) : (
                        <ErrorMessage>
                            Sorry. It looks like the payment type you selected is not
                            supported by your device. Please{' '}
                            <span
                                onClick={e => {
                                    e.preventDefault();
                                    window.close();
                                }}
                            >
                                go back
                            </span>{' '}
                            and select a different method.
                        </ErrorMessage>
                    )}
                    <Footer>
                        <button
                            onClick={e => {
                                e.preventDefault();
                                window.close();
                            }}
                        >
                            Cancel
                        </button>
                    </Footer>
                </Body>
            </React.Fragment>
        );
    }
}

export default App;
