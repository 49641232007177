function getUrlVars() {
    var vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = decodeURIComponent(value);
    });
    return vars;
}

const parseUrlParams = () => {
    let params = getUrlVars();
    if (params.apiData)
        params.apiData = JSON.parse(params.apiData);
    return params;
}

export const isLongText = (text, length) => {
    if (text !== undefined) {
        const isLong = text.length > length ? true : false;
        return isLong;
    }
};

export const urlParams = parseUrlParams();
