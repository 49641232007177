const productionGooglePayMerchantId = '01520232363246397998';
const testOrgHunterPublicKey = 'pk_test_kDZAGD17wmYYuIowhcGTY7DE';
const productionOrgHunterPublicKey = 'pk_live_nKFDu2Q2M5XwRBvQeArgOaMg';

const configLocal = {
    API_BASE_URL: 'https://dev-api.actionbutton.co:44370/api/',
    GOOGLE_MERCHANT_ID: null,
    GOOGLE_PAY_ENVIRONMENT: 'TEST',
    ORG_HUNTER_PUBLIC_KEY: testOrgHunterPublicKey
};

const configQa = {
    API_BASE_URL: 'https://qa-api.actionbutton.co/api/',
    GOOGLE_MERCHANT_ID: null,
    GOOGLE_PAY_ENVIRONMENT: 'TEST',
    ORG_HUNTER_PUBLIC_KEY: testOrgHunterPublicKey
};

const configStage = {
    API_BASE_URL: 'https://stage-api.actionbutton.co/api/',
    GOOGLE_MERCHANT_ID: null,
    GOOGLE_PAY_ENVIRONMENT: 'TEST',
    ORG_HUNTER_PUBLIC_KEY: testOrgHunterPublicKey
};

const configProduction = {
    API_BASE_URL: 'https://api.actionbutton.co/api/',
    GOOGLE_MERCHANT_ID: productionGooglePayMerchantId,
    GOOGLE_PAY_ENVIRONMENT: 'PRODUCTION',
    ORG_HUNTER_PUBLIC_KEY: productionOrgHunterPublicKey
};

const getConfig = () => {
    switch (process.env.REACT_APP_ENV || process.env.REACT_STATIC_ENV) {
        case 'node':
            return configLocal;
        case 'local':
            return configLocal;
        case 'qa':
            return configQa;
        case 'stage':
            return configStage;
        case 'production':
            return configProduction;
        default:
            throw new Error('Environment not valid!');
    }
};

const config = getConfig();

export default config;
